var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Drawer",
        {
          attrs: {
            title:
              (_vm.type ? (_vm.type === "edit" ? "编辑" : "复制") : "新增") +
              "排班日历",
            placement: "right",
            size: "normal",
            closable: "",
            "z-index": 8,
            visible: _vm.visible,
            "is-mask": false,
          },
          on: { onSubmit: _vm.onSave, close: _vm.reset },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "a-form-model",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                    onkeydown: "if(event.keyCode===13){return false}",
                  },
                },
                [
                  _c(
                    "a-form-model-item",
                    {
                      ref: "shiftSchedulingName",
                      attrs: {
                        label: "排班日历名称",
                        prop: "shiftSchedulingName",
                      },
                    },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入排班日历名称" },
                        model: {
                          value: _vm.form.shiftSchedulingName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "shiftSchedulingName", $$v)
                          },
                          expression: "form.shiftSchedulingName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      ref: "shiftCycleType",
                      attrs: { label: "排班日历类型", prop: "shiftCycleType" },
                    },
                    [
                      _c("a-radio-group", {
                        attrs: { options: _vm.shift_cycle_type },
                        on: { change: _vm.changeShiftCycleType },
                        model: {
                          value: _vm.form.shiftCycleType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "shiftCycleType", $$v)
                          },
                          expression: "form.shiftCycleType",
                        },
                      }),
                      _c("Checkcycle", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.shiftCycleType !== 3,
                            expression: "form.shiftCycleType !== 3",
                          },
                        ],
                        attrs: { options: _vm.options },
                        on: { checked: _vm.getcheckList },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.shiftCycleType === 3,
                          expression: "form.shiftCycleType === 3",
                        },
                      ],
                      ref: "shiftSchedulingType",
                      attrs: { label: "排班方式", prop: "shiftSchedulingType" },
                    },
                    [
                      _c("a-radio-group", {
                        attrs: { options: _vm.shift_scheduling_type },
                        model: {
                          value: _vm.form.shiftSchedulingType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "shiftSchedulingType", $$v)
                          },
                          expression: "form.shiftSchedulingType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.shiftCycleType === 3 &&
                  _vm.form.shiftSchedulingType !== 2
                    ? _c(
                        "a-form-model-item",
                        {
                          ref: "cycleDays",
                          attrs: { label: "排班循环周期", prop: "cycleDays" },
                        },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "120px" },
                              on: { change: _vm.init },
                              model: {
                                value: _vm.form.cycleDays,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "cycleDays", $$v)
                                },
                                expression: "form.cycleDays",
                              },
                            },
                            _vm._l(30, function (item) {
                              return _c(
                                "a-select-option",
                                { key: item + 1, attrs: { value: item + 1 } },
                                [_vm._v(" " + _vm._s(item + 1) + " ")]
                              )
                            }),
                            1
                          ),
                          _c("Checkcycle", {
                            attrs: { options: _vm.options },
                            on: { checked: _vm.getcheckList },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-form-model-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.shiftSchedulingType !== 2,
                          expression: "form.shiftSchedulingType !==2",
                        },
                      ],
                      attrs: { label: "班次选择" },
                    },
                    [
                      _c("SelectOrder", {
                        attrs: {
                          "check-list": _vm.options,
                          "work-list": _vm.workList,
                          "nowork-list": _vm.noworkList,
                        },
                        on: { change: _vm.getShift },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: "同步法定节假安排",
                        prop: "syncHolidayFlag",
                      },
                    },
                    [
                      _c("a-switch", {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.form.syncHolidayFlag,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "syncHolidayFlag", $$v)
                          },
                          expression: "form.syncHolidayFlag",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "特殊日期设置", prop: "specialDays" } },
                    [
                      _c(
                        "a-button",
                        {
                          staticClass: "addBtn",
                          attrs: { type: "primary", prefix: "add-one" },
                          on: { click: _vm.addDate },
                        },
                        [_vm._v(" 添加日期 ")]
                      ),
                      _vm._l(_vm.form.specialDays, function (item, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "addDate" },
                          [
                            _c("a-date-picker", {
                              attrs: { "value-format": "YYYYMMDD" },
                              model: {
                                value: item.specialDate,
                                callback: function ($$v) {
                                  _vm.$set(item, "specialDate", $$v)
                                },
                                expression: "item.specialDate",
                              },
                            }),
                            _c(
                              "a-select",
                              {
                                staticClass: "select-box",
                                attrs: { placeholder: "请选择班次" },
                                model: {
                                  value: item.shiftId,
                                  callback: function ($$v) {
                                    _vm.$set(item, "shiftId", $$v)
                                  },
                                  expression: "item.shiftId",
                                },
                              },
                              _vm._l(_vm.shiftList, function (shift) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: shift.shiftId,
                                    attrs: { value: shift.shiftId },
                                  },
                                  [_vm._v(" " + _vm._s(shift.shiftName) + " ")]
                                )
                              }),
                              1
                            ),
                            _c(
                              "svg",
                              {
                                staticClass: "iconpark-icon delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.del(i)
                                  },
                                },
                              },
                              [
                                _c("use", {
                                  attrs: { href: "#delete-5no76872" },
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "sufFooter" },
            [
              _c(
                "a-tooltip",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.type === "edit",
                      expression: "type === 'edit'",
                    },
                  ],
                  attrs: { placement: "topRight", trigger: "hover" },
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("div", [_vm._v("修改点将于明日生效")]),
                  ]),
                  _c("a-icon", {
                    staticClass: "exclamation-circle",
                    attrs: { type: "exclamation-circle", theme: "filled" },
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }